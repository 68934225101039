@import '../../styles/variables';

.login-container {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 500px;
      margin-bottom: 100px;
    }

    .login-button {
      width: 150px;
      height: 35px;
      color: white;
      background-color: $primary-color;
      font-weight: bold;
      text-transform: none;
      font-size: 16px;
    }
  }
}
