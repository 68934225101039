@import '../../styles/variables';

.twilio {
  z-index: 100;
  position: fixed;
  bottom: 20px;
  right: 80px;
  width: 200px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 15px;

  .MuiButton-outlined {
    border-color: $primary-color;
    color: $primary-color;
    text-transform: capitalize;
    height: 35px;
  }

  .MuiIconButton-root {
    width: max-content;
  }

  .MuiFab-root {
    background-color: white;

    .MuiSvgIcon-root {
      color: $primary-color;
    }
  }

  .MuiInputBase-input.Mui-disabled {
    color: black;
    -webkit-text-fill-color: black;
  }

  .MuiInputLabel-root.Mui-disabled {
    color: $primary-color;
  }

  .MuiInputLabel-root.Mui-focused {
    color: $primary-color;
  }

  .MuiInputLabel-root {
    color: $primary-color;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom-color: $primary-color;
  }

  .MuiInput-underline:after {
    border-bottom-color: $primary-color;
  }

  .progress {
    background-color: $primary-color-transparent;

    .MuiLinearProgress-bar {
      background-color: $primary-color;
    }
  }

  .options {
    display: flex;
    gap: 10px;

    .MuiButtonBase-root {
      width: 100%;
    }

    .reject {
      color: red;
      border-color: red;
    }
  }

  .pulse-animation {
    animation: pulse 1.5s infinite ease-in-out;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 rgba(255, 0, 0, 0.7);
    }
    50% {
      transform: scale(1.2);
      box-shadow: 0 0 15px rgba(255, 0, 0, 0.9);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 rgba(255, 0, 0, 0.7);
    }
  }
}
