@import '../../styles/variables';

.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-top: 25px;

  .MuiButtonBase-root {
    background-color: $primary-color;
    color: white;
    text-transform: none;
  }

  .MuiButtonBase-root:hover {
    background-color: $primary-color;
  }
}
