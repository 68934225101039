@import '../../styles/variables';

.upload-detail {
  background-color: white;
  border-radius: 8px;
  margin: 0 25px;

  h1 {
    font-size: 24px;
    text-align: center;
    margin: 25px;
  }

  .upload-detail-header {
    display: flex;
    gap: 5px;

    span {
      font-weight: bold;
    }

    p {
      margin: 0;
    }
  }

  .upload-detail-filters {
    margin: 25px 0;
    display: flex;
    gap: 25px;

    .MuiButtonBase-root {
      height: 40px;
      width: 150px;
      text-transform: none;
      color: $primary-color;
      border-color: $primary-color;
      padding: 0;
    }

    .queue-filter {
      span {
        margin-left: 5px;
        text-transform: capitalize;
        color: black;
      }
    }

    .search-filter {
      .MuiInputBase-root {
        width: 240px;
        height: 40px;

        .MuiInputBase-input {
          width: 100%;
        }

        input::placeholder {
          font-size: 14px;
        }

        .MuiButtonBase-root {
          width: 35px;
          height: 35px;
          margin-right: 0;
        }
      }
    }

    .actions {
      display: flex;
      gap: 10px;

      .loader {
        height: 40px;
      }
    }
  }

  .upload-detail-content {
    .MuiTableContainer-root {
      overflow-x: visible;

      .MuiTableRow-root.sent {
        .MuiTableCell-root {
          color: green;
        }
      }

      .MuiTableRow-root.unfit {
        .MuiTableCell-root {
          color: orange;
        }
      }
    }

    .MuiTableCell-root {
      padding: 5px;
      max-width: 185px;

      .MuiFormControl-root.multi {
        margin: 5px 0;
      }

      .MuiCheckbox-root.Mui-checked {
        color: $primary-color;
      }

      .MuiButtonBase-root {
        padding: 0;
      }
    }

    .collapsible-row {
      input {
        text-align: center;
        border-radius: 5px;
      }

      input.warning {
        border: 1px solid orange;
      }

      .MuiInputBase-root {
        height: 30px;
        width: 190px;
      }

      .required {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid red;
        }
      }

      a {
        color: black;
        text-decoration: none;
      }

      a:hover {
        color: $primary-color;
        cursor: pointer;
      }

      .phone-numbers {
        .MuiFormControl-root {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 2px;
        }
      }

      .mismatch {
        color: orange;
      }
    }

    .collapse {
      .MuiTableCell-root {
        border-bottom: none;
        padding: 0;

        .MuiCollapse-root {
          .MuiCollapse-wrapper {
            border-bottom: 1px solid lightgray;
            padding-left: 10px;

            .MuiBox-root {
              display: flex;
              justify-content: center;

              .MuiFormControl-root {
                width: 100%;
                margin: 0;

                textarea {
                  width: 100%;
                }
              }

              .actions {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                margin-left: 10px;
              }
            }
          }
        }
      }

      .MuiSvgIcon-root.check {
        color: green;
      }

      .MuiSvgIcon-root.close {
        color: red;
      }
    }

    input {
      width: 100%;
      height: 25px;
      border: none;
      text-align: center;
      cursor: pointer;
    }

    input:hover {
      border-bottom: 1px solid #20ce99;
      margin-top: 1px;
    }

    input:focus {
      outline: none;
      border: 1px solid #20ce99;
      border-radius: 5px;
    }

    .MuiPagination-root {
      margin-top: 25px;
      display: flex;
      justify-content: center;
    }
  }
}

.upload-detail.loading {
  margin: 5px;
  height: 50vh;
  border-radius: 0;
  display: flex;
  justify-content: center;
}

.toggle {
  display: flex;
  justify-content: center;

  .Mui-selected {
    .MuiSvgIcon-root {
      color: $primary-color;
    }
  }
}

.number.MuiMenuItem-root {
  .MuiIconButton-root {
    margin-left: 5px;
  }
}
