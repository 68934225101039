@import '../../styles/variables';

.dashboard {
  margin-top: 15px;

  .filters {
    padding: 15px 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .MuiButtonBase-root {
      height: 40px;
      text-transform: none;
      color: $primary-color;
      border-color: $primary-color;
      padding: 0;
    }

    .patient-queue-filter {
      .MuiButtonBase-root {
        padding: 5px;
      }

      p {
        margin: 0 0 0 5px;
        color: black;
      }
    }

    .date-filters {
      display: flex;
      gap: 10px;

      .MuiFormControl-root {
        .MuiInputLabel-shrink {
          top: 0;
        }
      }

      .MuiInputBase-root {
        input {
          padding: 8.5px 14px;
          width: 100px;
        }

        svg {
          color: $primary-color;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: $primary-color;
      }
    }

    .reset {
      width: 100px;
    }

    .refresh {
      .loader {
        height: auto;
      }

      .MuiSvgIcon-root {
        height: 40px;
        width: 40px;
      }
    }

    .search-filter {
      .MuiInputBase-root {
        width: 250px;
        height: 40px;

        .MuiInputBase-input {
          width: 100%;
        }

        input::placeholder {
          font-size: 14px;
        }

        .MuiButtonBase-root {
          width: 35px;
          height: 35px;
          margin-right: 0;
        }
      }
    }

    .specialty-filter,
    .referral-status-filter {
      height: 35px;

      .MuiOutlinedInput-notchedOutline {
        border-color: $primary-color;
      }

      .MuiSelect-icon {
        color: $primary-color;
      }
    }
  }

  .patient-table {
    margin: 15px;

    .MuiTableContainer-root {
      max-height: 75vh;

      .loader {
        position: absolute;
        width: 100%;
      }

      .MuiTableRow-root {
        .saved {
          background-color: $tagged-background-color;
        }
      }

      .MuiTableCell-root.tag {
        padding: 0;

        .MuiSvgIcon-root {
          padding-left: 5px;
          color: $primary-color;
        }

        .MuiSvgIcon-root:hover {
          cursor: pointer;
        }
      }

      .MuiTableCell-root {
        padding: 5px;

        a {
          text-decoration: none;
          color: black;
        }

        a:hover {
          color: $primary-color;
        }

        .MuiSvgIcon-root {
          margin-top: 5px;
        }
      }

      .needs-action {
        position: relative;

        .MuiChip-root {
          margin-left: 5px;
        }

        .first-action {
          display: flex;
        }

        .more-actions {
          display: none;
        }

        &:hover {
          .more-actions {
            display: flex;
            position: absolute;
            right: 87%;
            background-color: white;
          }
        }

        .MuiChip-root {
          border-color: red;
          color: red;
          width: max-content;
        }
      }

      .referrals {
        p {
          margin: 0;
        }
      }
    }

    .collapse {
      .MuiBox-root {
        display: flex;
        justify-content: center;

        .actions {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          margin-left: 10px;
        }
      }

      .MuiTableCell-root {
        padding: 0;
        border-bottom: none;
      }

      .MuiFormControl-root {
        width: 90%;
        margin: 0;

        textarea {
          width: 100%;
        }
      }

      .MuiCollapse-root {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
      }
    }

    .MuiSvgIcon-root.check {
      color: green;
    }

    .MuiSvgIcon-root.red {
      color: red;
    }

    .MuiSvgIcon-root.gray {
      color: gray;
    }
  }
}

.table-filter {
  .MuiButtonBase-root {
    width: 162px;
  }

  p {
    margin: 0 5px;
    color: black;
  }

  .MuiInputBase-root {
    width: 200px;
    height: 50px;
  }
}

.table-filter-menu {
  h3 {
    margin: 5px 12px;
  }

  .MuiButtonBase-root {
    padding: 5px;
  }

  .MuiCheckbox-root.Mui-checked {
    color: $primary-color;
  }

  .all {
    display: flex;
    justify-content: center;
  }
}
