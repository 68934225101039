@import '../../styles/variables';

.date-time-picker {
  .MuiInputBase-root {
    width: 225px;
    height: 35px;

    &.Mui-disabled {
      input {
        -webkit-text-fill-color: black;
      }
    }
  }
}
