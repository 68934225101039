@import '../../styles/variables';

.widgets {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;

  .fabs {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .MuiFab-root {
      background-color: white;

      .MuiSvgIcon-root {
        color: $primary-color;
      }
    }
  }
}
