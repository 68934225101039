@import '../../styles/variables';

.loading-button.MuiButtonBase-root {
  border-color: $primary-color;
  color: $primary-color;
  height: 35px;
  padding: 10px;
  text-transform: none;
  border: none;
  align-items: center;

  .MuiSvgIcon-root {
    margin-right: 5px;
    height: 25px;
    width: 25px;
  }

  .loader {
    margin-right: 5px;
  }
}

.loading-button.MuiButtonBase-root:hover {
  border: none;
  background-color: #f2f2f2;
}

.loading-button.MuiButtonBase-root.Mui-disabled {
  color: $primary-color;
  border: none;
}
