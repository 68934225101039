@import '../../styles/variables';

.audio-player {
  .MuiBadge-root {
    .MuiBadge-badge {
      top: 5px;
      right: 5px;
    }
  }
}
