@import '../../styles/variables';

.audit-history {
  width: 95%;
  margin: 0 auto;

  h3 {
    margin: 20px;
    text-align: center;
    font-size: 24px;
  }

  .filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    padding: 15px 0;

    .action {
      width: 150px;
    }

    .category {
      width: 150px;
    }

    .users {
      width: 250px;
    }

    .date-filter {
      display: flex;
      gap: 10px;

      .MuiFormLabel-root {
        color: black;
      }

      .MuiOutlinedInput-root {
        width: 150px;
        height: 40px;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: $primary-color;
      }

      .MuiSvgIcon-root {
        color: $primary-color;
      }
    }

    .reset {
      width: 50px;
      height: 40px;
      text-transform: none;
      color: $primary-color;
      border: 1px solid $primary-color;

      p {
        margin: 0 0 0 5px;
        color: black;
      }
    }

    .loader {
      height: auto;
    }

    .refresh {
      padding: 5px;

      .MuiSvgIcon-root {
        color: $primary-color;
        width: 35px;
        height: 35px;
      }
    }
  }

  .history {
    .MuiIconButton-root {
      padding: 5px;
    }

    .MuiIconButton-root:hover {
      color: $primary-color;
    }

    .MuiTableContainer-root {
      max-height: 75vh;
    }
  }
}
