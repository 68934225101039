@import '../../styles/variables';

.confirmation {
  .MuiPaper-root {
    width: 35%;
    padding: 5px;

    .MuiDialogActions-root {
      display: flex;
      justify-content: center;
      gap: 45%;

      button {
        width: 100px;
        height: 35px;
      }

      .cancel {
        border: 1px solid red;
        text-transform: capitalize;
        color: red;
      }

      .confirm {
        border: 1px solid $primary-color;
        text-transform: capitalize;
        color: $primary-color;
      }
    }
  }
}

.confirmation-modal {
  .MuiPaper-root {
    width: 400px;

    .MuiDialogTitle-root {
      text-align: center;
    }

    .MuiDialogContent-root {
      padding-top: 0;

      h4 {
        margin: 0;
        text-decoration: underline;
        font-size: 16px;
      }

      .row {
        margin: 10px 0;
        display: flex;
        align-items: center;

        .bold {
          font-weight: bold;
          margin-right: 5px;
        }

        p {
          font-size: 14px;
          margin: 0;
        }

        .MuiInputBase-root {
          height: 25px;
          width: 125px;
        }

        .referral {
          min-width: 250px;
        }
      }

      .notes {
        .MuiInputBase-root {
          width: 350px;
        }
      }

      button {
        background-color: $primary-color;
        text-transform: none;
      }

      .loader {
        height: auto;
      }
    }

    .appointment-info {
      display: flex;
      flex-direction: column;

      p {
        font-weight: bold;
      }

      .reason {
        height: 45px;
        margin-bottom: 20px;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;

      .cancel {
        background-color: white;
        border-color: orange;
        color: orange;
      }
    }
  }
}
