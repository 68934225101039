.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 10px;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  width: 75%;
  height: 250px;

  &.open {
    transform: translate(-50%, 0);
  }

  &.closed {
    transform: translate(-50%, 120%);
  }

  @media (max-width: 900px) {
    width: 90%;
  }
}
