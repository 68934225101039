@import '../../styles/variables';

.patient-profile {
  margin: 35px auto;
  width: 75%;

  .section {
    margin-bottom: 50px;

    h3 {
      margin: 0 0 25px 0;
      font-size: 20px;
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    gap: 25px;

    .MuiTextField-root {
      width: 300px;
    }
  }

  .discharges {
    .loader {
      height: auto;
    }

    .MuiTableContainer-root {
      border: 1px solid black;
      border-radius: 5px;
      max-height: 300px;

      .MuiTableRow-root:nth-of-type(odd) {
        background-color: #eeeeee;
      }

      .campaign-start {
        cursor: pointer;
        text-decoration: underline;
      }

      .campaign-inactive {
        cursor: default;
        text-decoration: none;
      }
    }
  }
}

.patient-appointments {
  .MuiTableContainer-root {
    border: 1px solid black;
    border-radius: 5px;
    max-height: 300px;

    .MuiTableCell-root {
      padding: 5px;
    }

    .MuiSvgIcon-root {
      display: flex;
      margin: 0 auto;
    }
  }

  .MuiButtonBase-root {
    padding: 0;

    h3 {
      margin: 0;
    }
  }
}

.center {
  justify-content: center;
}

.table-filter-menu.appointment-type {
  .MuiPaper-root {
    min-width: 150px;

    .MuiButtonBase-root {
      padding: 5px 10px;
    }
  }
}

.confirmation-modal {
  .MuiPaper-root {
    width: 400px;

    .MuiDialogTitle-root {
      text-align: center;
    }

    .MuiDialogContent-root {
      padding-top: 0;

      h4 {
        margin: 0;
        text-decoration: underline;
        font-size: 16px;
      }

      .row {
        margin: 10px 0;
        display: flex;
        align-items: center;

        .bold {
          font-weight: bold;
          margin-right: 5px;
        }

        p {
          font-size: 14px;
          margin: 0;
        }

        .MuiInputBase-root {
          height: 25px;
          width: 125px;
        }

        .referral {
          min-width: 250px;
        }
      }

      .notes {
        .MuiInputBase-root {
          width: 350px;
        }
      }

      button {
        background-color: $primary-color;
        text-transform: none;
      }

      .loader {
        height: auto;
      }
    }

    .appointment-info {
      display: flex;
      flex-direction: column;

      p {
        font-weight: bold;
      }

      .reason {
        height: 45px;
        margin-bottom: 20px;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;

      .cancel {
        background-color: white;
        border-color: orange;
        color: orange;
      }
    }
  }
}

.department {
  .info {
    margin-left: 5px;
    h4 {
      margin: 0;
    }

    p {
      margin: 0;
      font-size: 12px;
      color: gray;
    }
  }
}

@media (max-width: 900px) {
  .patient-profile {
    margin: 50px 25px;

    .section {
      .content {
        .phone-numbers {
          .phone {
            gap: 5px;

            a {
              font-size: 14px;
            }

            .MuiChip-root {
              width: 75px;
            }
          }
        }

        .contact-attempts {
          .attempt {
            gap: 5px;

            .date-time-picker {
              .MuiInputBase-root {
                width: 200px;
              }
            }
          }
        }
      }
    }
  }
}

.provider-list {
  .MuiPaper-root {
    width: 40%;

    h2 {
      text-align: center;
    }

    .specialty-filter {
      margin: 10px;

      .MuiButtonBase-root {
        color: $primary-color;
        border-color: $primary-color;
        text-transform: capitalize;
        width: 200px;

        span {
          margin-left: 5px;
          color: black;
        }
      }
    }

    .provider {
      margin: 10px;
      border: 1px solid gray;
      border-radius: 10px;
      padding: 10px;

      .names {
        border-bottom: 1px solid gray;
        font-size: 18px;
        display: flex;
        justify-content: space-between;

        .name {
          font-weight: bold;
          color: $primary-color;
        }

        .specialty {
          color: gray;
        }
      }

      .address {
        margin-top: 10px;

        .name {
          font-weight: bold;
        }
      }
    }
  }
}
