@import '../../styles/variables';

.patient-wizard {
  z-index: 100;
  position: fixed;
  bottom: 20px;
  right: 80px;
  width: 400px;
  min-height: 300px;

  .MuiButtonBase-root {
    min-height: 50px;
  }

  .Mui-expanded {
    margin: 0;
  }

  .MuiAccordionDetails-root {
    max-height: 350px;
    overflow-y: auto;
  }

  .MuiAccordionSummary-content {
    font-weight: 500;
  }

  h5 {
    font-size: 16px;
    margin: 0 0 0 15px;
  }

  .restrictions {
    h4 {
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 15px;
    }

    h5 {
      border-bottom: 1px solid black;
    }

    p {
      color: red;
      margin: 0 0 0 15px;
    }
  }

  .new-patient-acceptance {
    .provider {
      margin: 15px 0;

      h5 {
        margin: 0;
      }

      p {
        margin: 0;
      }
    }
  }

  .affiliates {
    padding: 5px;

    .MuiTableCell-root {
      padding: 5px;
      font-size: 14px;

      p {
        margin: 0;
      }
    }
  }

  .accepted-payors {
    padding: 5px;

    h4 {
      margin-bottom: 0;
    }

    p {
      font-size: 12px;
      margin: 5px;
    }

    .MuiList-root {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 300px;

      .MuiListItem-root {
        font-size: 12.5px;
        width: auto;
        padding: 5px 2px;
      }
    }
  }

  .self-pay-costs {
    p {
      margin: 0;
    }
  }

  .important-notes {
    .note {
      padding: 10px 0;
      border-bottom: 1px solid black;

      h5 {
        margin: 0;
      }

      p {
        margin: 5px 0;
      }

      span {
        color: $primary-color;
      }

      .subtext {
        font-size: 14px;
        padding-left: 15px;
      }

      .subtext.indent {
        padding-left: 30px;
      }
    }
  }
}
