@import './styles/_variables';

.App {
  font-family: 'Roboto', sans-serif;
  height: 100%;

  .loading {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.MuiList-root {
  max-height: 250px;

  .MuiListSubheader-root {
    border-bottom: 1px solid black;
    color: black;
    line-height: 24px;
    font-size: 16px;
    margin: 0 16px;
    padding: 0;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.bold {
  font-weight: bold !important;
}

.capitalize {
  text-transform: capitalize;
}

.hover {
  cursor: pointer;
}

.green {
  color: $primary-color;
}

.red {
  color: red !important;
}

.gray {
  color: gray;
}

.orange {
  color: orange !important;
}

.gold {
  color: gold !important;
}

.purple {
  color: darkviolet !important;
}

.white {
  color: white !important;
}

.pointer {
  cursor: pointer;
}

.yellow-background {
  background-color: rgba(247, 210, 142, 0.25);
}

.yellow-background-input {
  background-color: rgba(247, 210, 142, 0);
}

.orange-background {
  background-color: rgba(255, 171, 16, 0.25);
}

.orange-background-input {
  background-color: rgb(255, 165, 0, 0);
}

.red-background {
  background-color: rgba(255, 150, 150, 0.25);
}

.red-background-input {
  background-color: rgba(255, 150, 150, 0);
}

.primary-color {
  color: $primary-color !important;
  border-color: $primary-color !important;
}
