@import '../../styles/variables';

.multi-select {
  height: 40px;

  .MuiInputLabel-outlined {
    color: black;
  }

  .MuiInputBase-root {
    .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color;
    }

    .MuiSelect-icon {
      color: $primary-color;
    }
  }
}

.multi-select-all.MuiMenuItem-root {
  justify-content: center;
  font-weight: bold;
}
