@import '../../styles/variables';

.nav {
  display: flex;
  height: 65px;

  .MuiToolbar-root {
    img {
      width: 150px;
    }

    .subtitle {
      color: black;
      margin-left: 5px;
      font-size: 14px;
      font-weight: bold;
    }

    button {
      background-color: white;
      border-radius: 5px;
      width: 100px;
      height: 30px;
      color: $primary-color;
      cursor: pointer;
      text-transform: none;
    }

    .MuiTypography-root {
      color: black;
    }
  }

  .link {
    margin: 0 5px;
  }

  .link:hover {
    border: 1px solid $primary-color;
  }

  .user-dropdown {
    display: flex;
    color: black;
    margin-left: auto;

    button {
      width: 100%;
      height: 100%;

      .MuiSvgIcon-root {
        margin-right: 10px;
        font-size: 40px;
      }
    }

    .user-info {
      text-align: left;
    }
  }
}
