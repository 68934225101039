@import '../../styles/variables';

.settings {
  margin: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  h1 {
    font-size: 24px;
    text-align: center;
    margin: 25px;
  }

  .MuiPaper-root {
    min-width: 50%;
    text-align: center;

    h3 {
      margin: 0;
      padding: 10px;
    }

    .list {
      max-height: 500px;
      text-align: left;
      list-style-type: square;
      overflow: scroll;
      border-bottom: 1px solid lightgray;
      margin: 0;

      li {
        margin: 10px;
      }
    }

    .inclusions {
      .MuiTableCell-root {
        padding: 10px;

        .MuiInputBase-root {
          width: 200px;
          height: 35px;
        }

        .remove {
          border: 1px solid red;
          color: red;
          text-transform: capitalize;
          width: 100px;
          height: 35px;
        }
      }
    }

    .actions {
      padding: 15px;
      display: flex;
      justify-content: center;
      gap: 25px;

      .MuiInputBase-root {
        width: 200px;
        height: 35px;
      }
    }

    .add {
      background-color: $primary-color;
      color: white;
      text-transform: capitalize;
      width: 100px;
      height: 35px;
    }

    .loader {
      height: max-content;
      width: 25%;
    }
  }
}
