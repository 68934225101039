@import '../../styles/variables';

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader .MuiCircularProgress-root {
  color: $primary-color;
}

.loader.centered {
  height: 50vh;
}
