@import '../../styles/variables';

.upload {
  background-color: white;
  border-radius: 8px;
  margin: 0 50px;

  .MuiAlert-root {
    margin-top: 15px;
  }

  h1 {
    font-size: 24px;
    text-align: center;
    margin: 25px;
  }

  .upload-header {
    position: sticky;
    top: 65px;
    background-color: white;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .file-upload {
      display: flex;

      span {
        color: black;
        font-size: 22px;
        font-weight: bold;
        margin-left: 5px;
      }

      button:hover {
        background: none;
      }

      svg {
        color: #20ce99;
        font-size: 48px;
      }

      input {
        display: none;
      }

      .file-info {
        display: flex;
        align-items: center;
      }

      .MuiCircularProgress-root {
        margin-top: 10px;
      }

      .button {
        background-color: #20ce99;
        text-transform: none;
        margin: 0 50px;
        padding: 0;
        width: 100px;
        height: 30px;
      }

      .button:hover {
        background-color: #20ce99;
      }

      a {
        text-decoration: none;
        color: white;
      }
    }

    .date-filters {
      display: flex;
      gap: 10px;

      .MuiFormControl-root {
        label {
          top: -8px;
        }

        .MuiInputLabel-shrink {
          top: 0;
        }
      }

      .MuiInputBase-root {
        input {
          padding: 8.5px 14px;
          width: 100px;
        }

        svg {
          color: $primary-color;
        }
      }

      .MuiFormLabel-root {
        color: $primary-color;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: $primary-color;
      }
    }
  }

  .upload-content {
    .MuiTableContainer-root {
      a {
        color: black;
        text-decoration: none;
      }

      a:hover {
        color: $primary-color;
      }
    }
  }
}
